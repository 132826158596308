<template>
    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div>
                        <h3>1. Drain Connection <span class="help-popover" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="Choose the diameter of the drain/waste pipe that the Power-Pipe will be connected to."><i class="glyphicon glyphicon-question-sign"></i></span></h3>

                        <div class="row">
                                <div class="col-12 pb-5 p-l-10">
                                    <input class="checkbox-tools" type="radio" id="fifty" value="50" v-model="selectedDrain">
                                    <label class="for-checkbox-tools" for="fifty" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="50mm Power-Pipes are less expensive than the 110mm versions, however even though they are smaller, they usually take up more space as they have to be installed alongside the SVP from the bathroom.">
                                        50mm
                                    </label>
                                    <input class="checkbox-tools" type="radio" id="svp" value="110" v-model="selectedDrain">
                                    <label class="for-checkbox-tools" for="svp" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="110mm Power-Pipes directly replace a section of the SVP from the bathroom, which not only makes for a more straight forward installation, they take up less space (they only take a little bit more space than the SVP that they replace). They are also more efficient than the 50mm models.">
                                        110mm SVP
                                    </label>
                                </div>
                            </div>
                    </div>
                    <div>
                        <h3>2. Max Showers <span class="help-popover" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="Select the number of showers in the property to ensure that you do not have any issues with pressure loss created by the WWHR. If there is only one shower in the property it is best to select this so the model selector can recommend a higher efficiency unit where possible."><i class="glyphicon glyphicon-question-sign"></i></span></h3>
                        <div class="row">
                            <div class="col-12 pb-5 p-l-10">
                                <input class="checkbox-tools" type="radio" id="one" value="1" v-model="selectedShowers" :disabled="selectedDrain == 110">
                                <label class="for-checkbox-tools" for="one" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="Higher efficiency units where there is only one shower in the household. Option only available for 50mm Power-Pipes.">
                                    1
                                </label><!--
						--><input class="checkbox-tools" type="radio" id="three" value="3" v-model="selectedShowers">
                                <label class="for-checkbox-tools" for="three" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="For larger households with up to 3 showers.">
                                    3
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3>3. Performance</h3>
                        <div class="row">
                            <div class="col-12 pb-5 p-l-10">
                                <input class="checkbox-tools" type="radio" id="high" value="high" v-model="selectedPerformance">
                                <label class="for-checkbox-tools" for="high" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="Higher efficiency units are longer, but as the unit is larger, it is more expensive.">
                                    High Efficiency
                                </label><!--
						--><input class="checkbox-tools" type="radio" id="med" value="med" v-model="selectedPerformance">
                                <label class="for-checkbox-tools" for="med" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="A medium length unit, offering the optimum level of value and performance.">
                                    Balanced
                                </label>
                                <input class="checkbox-tools" type="radio" id="low" value="low" v-model="selectedPerformance">
                                <label class="for-checkbox-tools" for="low" data-trigger="hover" data-toggle="popover" data-placement="bottom" data-content="A shorter unit which is more cost effective. This can help meet budgets if only a smaller SAP uplift is required.">
                                    Cost Effective
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div>
                        <h3>Recommended Power-Pipe:</h3>
                    </div>
                    <div v-if="recommendedProduct.length == 0">
                        <p class="secondary-subheader alert alert-info"><u>Please select the options to view our recommended unit.</u></p>
                        <h4>Buy now from <a href="https://thecodestore.co.uk/product/power-pipe" target="_blank" title="Buy Power-Pipe Waste Water Heat Recovery UK">CodeStore</a>:</h4>
                        <a href="https://thecodestore.co.uk/product/power-pipe" title="Buy Power-Pipe Waste Water Heat Recovery UK'" target="_blank"><img class="img-responsive" src="/images/logos/2019-tcs-logo-green.png" /></a>
                        <p class="m-t-10"><b>T:</b> +44(0)151 324 0779</p>
                    </div>
                    <div v-else>
                        <h4>Model: {{ recommendedProduct[0].model }}</h4>
                        <div class="row">
                            <div class="col-sm-3">
                                <img class="img-responsive" :src="recommendedProduct[0].img" :title="'Power-Pipe ' + recommendedProduct[0].model"/>
                            </div>
                            <div class="col-sm-9">
                                <table class="table table-hover table-recommended table-condensed">
                                    <tbody>
                                        <tr><th>Diameter:</th><td> {{ recommendedProduct[0].drain }}mm</td></tr>
                                        <tr><th>Length:</th><td> {{ recommendedProduct[0].length }}mm</td></tr>
                                        <tr><th>Max showers:</th><td> {{ recommendedProduct[0].maxShowers }}</td></tr>
                                        <tr><th>Efficiency @ 9l/min SAP2009:</th><td> {{ recommendedProduct[0].sap2009 }}%</td></tr>
                                        <tr><th>Efficiency @ 11l/min SAP2012:</th><td> {{ recommendedProduct[0].sap2012 }}%</td></tr>
                                        <tr><th>Typical SAP uplift:</th><td> {{ recommendedProduct[0].sapUplift }}%</td></tr>
                                        <tr><th>RRP ex. VAT & delivery:</th><td> £{{ recommendedProduct[0].price }}</td></tr>
                                    </tbody>
                                </table>
                                <h4>Buy now from <a :href="'https://thecodestore.co.uk/product/power-pipe-' + recommendedProduct[0].model.toLowerCase()" target="_blank" title="Buy Power-Pipe Waste Water Heat Recovery UK">CodeStore</a>:</h4>
                                <a :href="'https://thecodestore.co.uk/product/power-pipe-' + recommendedProduct[0].model.toLowerCase()" :title="'Buy Power-Pipe ' + recommendedProduct[0].model + ' Waste Water Heat Recovery UK'" target="_blank"><img class="img-responsive" src="/images/logos/2019-tcs-logo-green.png" /></a>
                                <p class="m-t-10"><b>T:</b> +44(0)151 324 0779</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        name: 'ProductSelector',
        data: () => {
            return {
                selectedDrain: '',
                selectedShowers: '',
                selectedPerformance: '',
                models: [
                    {'model': 'R4-36', 'length': 910, 'drain': 110, 'series': 'R', 'maxShowers': 3, 'performance': 'low', 'price': 450, 'sap2009': 43.0, 'sap2012': 35.5, 'sapUplift': '10-12', 'img': '/images/models/r4-36.jpg'},
                    {'model': 'R4-60', 'length': 1520, 'drain': 110, 'series': 'R', 'maxShowers': 3, 'performance': 'med', 'price': 675, 'sap2009': 59.1, 'sap2012': 55.5, 'sapUplift': '15-17', 'img': '/images/models/r4-60.jpg'},
                    {'model': 'R4-84', 'length': 2130, 'drain': 110, 'series': 'R', 'maxShowers': 3, 'performance': 'high', 'price': 850, 'sap2009': 67.0, 'sap2012': 63.7, 'sapUplift': '20-22', 'img': '/images/models/r4-84.jpg'},
                    {'model': 'X2-36', 'length': 910, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'performance': 'low', 'price': 375, 'sap2009': 35.4, 'sap2012': 32.2, 'sapUplift': '7-8', 'img': '/images/models/r2-36.jpg'},
                    {'model': 'X2-60', 'length': 1520, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'performance': 'med', 'price': 475, 'sap2009': 49.5, 'sap2012': 46.2, 'sapUplift': '12-13', 'img': '/images/models/r2-60.jpg'},
                    {'model': 'X2-84', 'length': 2130, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'performance': 'high', 'price': 575, 'sap2009': 59.4, 'sap2012': 55.9, 'sapUplift': '15-16', 'img': '/images/models/r2-84.jpg'},
                    {'model': 'E2-36', 'length': 910, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'performance': 'low', 'price': 375, 'sap2009': 40.9, 'sap2012': 37.5, 'sapUplift': '9-10', 'img': '/images/models/r2-36.jpg'},
                    {'model': 'E2-60', 'length': 1520, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'performance': 'med', 'price': 475, 'sap2009': 54.3, 'sap2012': 50.7, 'sapUplift': '13-14', 'img': '/images/models/r2-60.jpg'},
                    {'model': 'E2-84', 'length': 2130, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'performance': 'high', 'price': 575, 'sap2009': 63.7, 'sap2012': 60.3, 'sapUplift': '19-20', 'img': '/images/models/r2-84.jpg'},
                ]
            }
        },
        watch: {
            selectedDrain(val) {
                if(val == 110) {
                    this.selectedShowers = '3';
                } else {
                    this.selectedShowers = '';
                }
            }
        },
        computed: {
            recommendedProduct() {
                let result;
                result = this.models.filter(model => model.drain == this.selectedDrain);
                result = result.filter(model => model.maxShowers == this.selectedShowers);
                result = result.filter(model => model.performance == this.selectedPerformance);
                return result;
            }
        },
        mounted() {
            $(function () {
                $('[data-toggle="popover"]').popover();
            });
        }
    }
</script>
