<template>
    <div id="testimonials">
        <section>
            <div class="text-center">
            <h2 class="main-subheader">What Others Have to Say</h2>
                <vue-horizontal-list :items="testimonials" :options="options">
                    <template v-slot:default="{item}">
                        <div class="item testimonial-item" style="">
                            <img class="img-responsive" :src="item.img"/>
                            <p>"{{item.content}}"</p>
                            <h4>{{item.name}}</h4>
                            <h4>{{ item.company }}</h4>
                        </div>
                    </template>
                </vue-horizontal-list>
            </div>
        </section>
    </div>
</template>

<script>
    import VueHorizontalList from 'vue-horizontal-list';
    export default {
        name: 'testimonials',
        components: {
            VueHorizontalList
        },
        data: () => {
            return {
                testimonials: [
                    {
                        name: 'Jonathan Moss',
                        company: 'Redrow Homes',
                        img: '/uploads/2016/10/Redrow-Homes-HHI.png',
                        content: 'When it comes to Waste Water Heat Recovery, Power-Pipe is the ideal solution for our properties as it is extremely cost-effective and easy to install. Using Power-Pipe has also allowed us to pass SAP regulations with certain house types which otherwise would have failed. It is the only system we have come across where 22mm connections come as standard, making it easy for our plumbers to incorporate when fitting it into homes. The ongoing advice and support from the companys technical team has been excellent so we are delighted to have Power-Pipes products and services on board at Redrow Homes.',
                        date: 'October 2017'
                    },
                    {
                        name: 'Steve Saward',
                        company: 'UK SAPs',
                        img: '/uploads/2016/11/uksapslogo.gif',
                        content: 'Great webinar on Waste Water Heat Recovery with the team at Power-Pipe. We would recommend it to all SAP assessors and specifiers who should bring themselves up to speed on the benefits of this technology as it could add real value to their clients’ projects.',
                        date: '2nd November, 2016'
                    },
                    {
                        name: 'Paul Reid',
                        company: 'SAP Assessor, EPC Wigan',
                        img: '/uploads/2016/11/epc-wigan.png',
                        content: 'Having joined the webinar presented by Power-Pipe, we found the presentation to be very informative. It gave us a clear understanding of the workings and installation of a WWHR system and how it can contribute to the energy efficiency of a dwelling and assist in our SAP Assessment role for developers and architects.',
                        date: '2nd November, 2016'
                    },
                    {
                        name: 'Keith Lane',
                        company: 'SAP Assessor',
                        img: '/uploads/2016/10/wpd9c54991_05_06.jpg',
                        content: 'As a SAP Assessor it is extremely challenging not to exceed the SAP Target Emissions Rate without using expensive components within the SAP calculation. Including a WWHRS within the calculation provides more flexibility in the build specification which in turn can help reduce the overall build costs.',
                        date: 'October 2017'
                    }
                ],
                options: {
                    responsive: [
                        {size: 1}
                        // {end: 576, size: 1},
                        // {start: 576, size: 2}

                        // {start: 576, end: 768, size: 2},
                        // {start: 768, end: 992, size: 3},
                        // {size: 4}
                    ],
                    list: {
                        // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
                        windowed: 1200,

                        // Because: #app {padding: 80px 24px;}
                        padding: 24
                    }
                },
            }
    },
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
