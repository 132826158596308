<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-md-offset-8 col-lg-2 col-lg-offset-10">
                <input class="form-control" v-model="filters.search.value" placeholder="Search..." />
            </div>
        </div>
        <v-table :data="models" :filters="filters" class="table table-striped m-t-10">
            <thead slot="head">
            <tr>
                <th>Model</th>
                <th>Drain connection</th>
                <th>Mains connection</th>
                <th>Length</th>
                <th v-if="show === 'C'">Efficiency @ 9l/min (SAP 2009)</th>
                <th v-else>Efficiency @ 11l/min (SAP 2011)</th>
                <th v-if="show !== 'C'">SAP10 Uplift<sup>*</sup></th>
                <th>Max Showers<sup>**</sup></th>
            </tr>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
            <tr v-for="row in displayData" :key="row.id">
                <td>{{ row.model }}</td>
                <td>{{ row.drain }}mm</td>
                <td>{{ row.mainsConnection }}mm</td>
                <td>{{ row.length }}mm</td>
                <td v-if="show === 'C'">{{ row.sap2009 }}%</td>
                <td v-else>{{ row.sap2012 }}%</td>
                <td>{{ row.sapUplift }}%</td>
                <td>{{ row.maxShowers }}</td>
            </tr>
            <tr v-if="displayData.length === 0" class="alert-danger text-center">
                <td colspan="7">
                    <i class="glyphicon glyphicon-minus-sign" /> No Power-Pipes found!
                </td>
            </tr>
            </tbody>
        </v-table>
        <p><b>* SAP10 Uplift</b> - this is the typical increase you can expect from within a SAP 10 calculation for a 3 bedroom house. The exact uplift will change depending upon the specificaiton of the rest of the house.</p>
        <p><b>** Max Showers</b> - this denotes the maximum recommended number of showers the Power-Pipe® is connected to in SAP System A configuration (equal flow). This is based on the pressure loss on the mains water created by the WWHR system and assumes a minimum starting mains water pressure of 3 bar.</p>
    </div>
</template>

<script>
export default {
    name: "PowerPipeTable",
    props: ['show'],
    data() {
        return {
            models: [
                {'model': 'R4-18', 'length': 460, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 21.7, 'sap2012': 19.3, 'sapUplift': '~3', 'img': '/images/models/r4-36.jpg'},
                {'model': 'R4-24', 'length': 610, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 32.0, 'sap2012': 28.8, 'sapUplift': '~6', 'img': '/images/models/r4-36.jpg'},
                {'model': 'R4-30', 'length': 760, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 41.3, 'sap2012': 37.8, 'sapUplift': '~10', 'img': '/images/models/r4-36.jpg'},
                {'model': 'R4-36', 'length': 910, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 43.0, 'sap2012': 39.5, 'sapUplift': '~10', 'img': '/images/models/r4-36.jpg'},
                {'model': 'R4-42', 'length': 1070, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 46.6, 'sap2012': 43.0, 'sapUplift': '~12', 'img': '/images/models/r4-36.jpg'},
                {'model': 'R4-48', 'length': 1220, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 53.5, 'sap2012': 49.8, 'sapUplift': '~13', 'img': '/images/models/r4-60.jpg'},
                {'model': 'R4-54', 'length': 1370, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 55.8, 'sap2012': 52.1, 'sapUplift': '~15', 'img': '/images/models/r4-60.jpg'},
                {'model': 'R4-60', 'length': 1520, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 59.1, 'sap2012': 55.5, 'sapUplift': '~15', 'img': '/images/models/r4-60.jpg'},
                {'model': 'R4-66', 'length': 1680, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 60.5, 'sap2012': 56.9, 'sapUplift': '~17', 'img': '/images/models/r4-60.jpg'},
                {'model': 'R4-72', 'length': 1830, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 43.5, 'sap2012': 60.0, 'sapUplift': '~19', 'img': '/images/models/r4-84.jpg'},
                {'model': 'R4-84', 'length': 2130, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 67.0, 'sap2012': 63.7, 'sapUplift': '~21', 'img': '/images/models/r4-84.jpg'},
                {'model': 'R4-96', 'length': 2440, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 69.0, 'sap2012': 65.8, 'sapUplift': '~23', 'img': '/images/models/r4-84.jpg'},
                {'model': 'R4-108', 'length': 2740, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 69.6, 'sap2012': 66.4, 'sapUplift': '~23', 'img': '/images/models/r4-84.jpg'},
                {'model': 'R4-120', 'length': 3050, 'drain': 110, 'series': 'R4', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 72.4, 'sap2012': 69.3, 'sapUplift': '~25', 'img': '/images/models/r4-84.jpg'},

                {'model': 'R2-24', 'length': 610, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 32.0, 'sap2012': 19.4, 'sapUplift': '~4', 'img': '/images/models/r2-36.jpg'},
                {'model': 'R2-30', 'length': 760, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 41.3, 'sap2012': 26.0, 'sapUplift': '~5', 'img': '/images/models/r2-36.jpg'},
                {'model': 'R2-36', 'length': 910, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 43.0, 'sap2012': 29.4, 'sapUplift': '~6', 'img': '/images/models/r2-36.jpg'},
                {'model': 'R2-42', 'length': 1070, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 46.6, 'sap2012': 34.3, 'sapUplift': '~7', 'img': '/images/models/r2-36.jpg'},
                {'model': 'R2-48', 'length': 1220, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 53.5, 'sap2012': 34.4, 'sapUplift': '~7', 'img': '/images/models/r2-60.jpg'},
                {'model': 'R2-54', 'length': 1370, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 55.8, 'sap2012': 38.6, 'sapUplift': '~9', 'img': '/images/models/r2-60.jpg'},
                {'model': 'R2-60', 'length': 1520, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 59.1, 'sap2012': 43.8, 'sapUplift': '~11', 'img': '/images/models/r2-60.jpg'},
                {'model': 'R2-66', 'length': 1680, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 60.5, 'sap2012': 45.3, 'sapUplift': '~12', 'img': '/images/models/r2-60.jpg'},
                {'model': 'R2-72', 'length': 1830, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 43.5, 'sap2012': 50.1, 'sapUplift': '~13', 'img': '/images/models/r2-84.jpg'},
                {'model': 'R2-84', 'length': 2130, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 67.0, 'sap2012': 52.8, 'sapUplift': '~14', 'img': '/images/models/r2-84.jpg'},
                {'model': 'R2-96', 'length': 2440, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 69.0, 'sap2012': 57.8, 'sapUplift': '~17', 'img': '/images/models/r2-84.jpg'},
                {'model': 'R2-108', 'length': 2740, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 69.6, 'sap2012': 60.2, 'sapUplift': '~19', 'img': '/images/models/r2-84.jpg'},
                {'model': 'R2-120', 'length': 3050, 'drain': 50, 'series': 'R2', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 72.4, 'sap2012': 60.9, 'sapUplift': '~19', 'img': '/images/models/r2-84.jpg'},

                {'model': 'X2-36', 'length': 910, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 35.4, 'sap2012': 32.2, 'sapUplift': '~7', 'img': '/images/models/r2-36.jpg'},
                {'model': 'X2-60', 'length': 1520, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 49.5, 'sap2012': 46.2, 'sapUplift': '~12', 'img': '/images/models/r2-60.jpg'},
                {'model': 'X2-84', 'length': 2130, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'mainsConnection': '22', 'sap2009': 59.4, 'sap2012': 55.9, 'sapUplift': '~15', 'img': '/images/models/r2-84.jpg'},
                {'model': 'E2-36', 'length': 910, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'mainsConnection': '22', 'sap2009': 40.9, 'sap2012': 37.5, 'sapUplift': '~9', 'img': '/images/models/r2-36.jpg'},
                {'model': 'E2-60', 'length': 1520, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'mainsConnection': '22', 'sap2009': 54.3, 'sap2012': 50.7, 'sapUplift': '~13', 'img': '/images/models/r2-60.jpg'},
                {'model': 'E2-84', 'length': 2130, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'mainsConnection': '22', 'sap2009': 63.7, 'sap2012': 60.3, 'sapUplift': '~19', 'img': '/images/models/r2-84.jpg'},

                {'model': 'C4-36', 'length': 910, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 34.8, 'sap2012': 31.6, 'sapUplift': null, 'img': '/images/models/r4-36.jpg'},
                {'model': 'C4-42', 'length': 1070, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 40.8, 'sap2012': 37.2, 'sapUplift': null, 'img': '/images/models/r4-36.jpg'},
                {'model': 'C4-48', 'length': 1280, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 43.5, 'sap2012': 39.9, 'sapUplift': null, 'img': '/images/models/r4-60.jpg'},
                {'model': 'C4-54', 'length': 1370, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 47.9, 'sap2012': 44.2, 'sapUplift': null, 'img': '/images/models/r4-60.jpg'},
                {'model': 'C4-60', 'length': 1520, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 50.4, 'sap2012': 46.7, 'sapUplift': null, 'img': '/images/models/r4-60.jpg'},
                {'model': 'C4-66', 'length': 1680, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 52.6, 'sap2012': 48.9, 'sapUplift': null, 'img': '/images/models/r4-60.jpg'},
                {'model': 'C4-72', 'length': 1830, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 57.1, 'sap2012': 53.4, 'sapUplift': null, 'img': '/images/models/r4-84.jpg'},
                {'model': 'C4-78', 'length': 1980, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 59.1, 'sap2012': 55.5, 'sapUplift': null, 'img': '/images/models/r4-84.jpg'},
                {'model': 'C4-84', 'length': 2130, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 63.1, 'sap2012': 59.6, 'sapUplift': null, 'img': '/images/models/r4-84.jpg'},
                {'model': 'C4-96', 'length': 2440, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 60.5, 'sap2012': 56.9, 'sapUplift': null, 'img': '/images/models/r4-84.jpg'},
                {'model': 'C4-108', 'length': 2740, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 68.9, 'sap2012': 65.7, 'sapUplift': null, 'img': '/images/models/r4-84.jpg'},
                {'model': 'C4-120', 'length': 3050, 'drain': 110, 'series': 'C', 'maxShowers': 5, 'mainsConnection': '28', 'sap2009': 70.8, 'sap2012': 67.6, 'sapUplift': null, 'img': '/images/models/r4-84.jpg'},

            ],
            filters: {
                search: { value: '', keys: ['model', 'length', 'drain', 'mainsConnection', 'price'] },
                custom: { value: this.show, keys: ['series']}
            }
        }
    },
    methods: {
        showFilter (filterValue, row) {
            return row.series = filterValue;
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>
