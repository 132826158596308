<template>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">Power-Pipe Webinar Booking</h4>
                </div>
                <div class="modal-body pp-contact-form new-header">
                    <p class="header-details">Fill out the form below to book a free webinar with us. We can tailor the webinar to cover;</p>
                    <ul>
                        <li>Overview of WWHR and how it works</li>
                        <li>How WWHR fits in with Building Regulation Part L and SAP Calculations</li>
                        <li>Upcoming changes to Building Regulation Part L, SAP10 and the Future Homes Standard</li>
                        <li>Specifying the most effective WWHR for your development</li>
                        <li>Practicalities of installing WWHR in your development</li>
                    </ul>
                    <form v-if="showForm" id="contactForm" @submit.prevent="submitForm">
                        <div class="form-group">
                            <label for="name">Your name*</label>
                            <input id="name" type="text" name="name" v-model="form.name" @input="$v.form.name.$touch">
                            <p v-if="$v.form.name.$invalid && $v.form.name.$dirty" class="alert-warning">The name is required.</p>
                        </div>
                        <div class="form-group">
                            <label for="email">Your email*</label>
                            <input id="email" type="email" name="email" v-model="form.email" @input="$v.form.email.$touch">
                            <p v-if="$v.form.email.$invalid && $v.form.email.$dirty" class="alert-warning">A valid email is required.</p>
                        </div>
                        <div class="form-group">
                            <label for="tel">Your telephone</label>
                            <input id="tel" type="text" name="tel" v-model="form.tel">
                        </div>
                        <div class="form-group">
                            <label for="message">Message*</label>
                            <textarea id="message" name="message" v-model="form.message" @input="$v.form.message.$touch"></textarea>
                            <p v-if="$v.form.message.$invalid && $v.form.message.$dirty" class="alert-warning">The message is required.</p>
                        </div>
                        <vue-recaptcha @verify="storeToken" @expired="token = null" sitekey="6LfF-aoZAAAAACFQzGjs1PR7jZC6KQFlESSFA00I" :loadRecaptchaScript="true">
                        </vue-recaptcha>
                        <div v-if="!submitActive" class="tooltip-wrapper" data-toggle="popover" data-trigger="hover" data-content="Please make sure the form is complete before you can submit the message.">
                            <button :disabled="!submitActive" class="btn btn-default contact-us" :class="{'disabled': !submitActive}">Submit</button>
                        </div>
                        <button v-else class="btn btn-default">Submit</button>
                        <p v-if="sendError" class="alert-danger">There was a problem sending your message: {{ sendError }}. <br> Your message could not be sent, please email or call us. Thanks</p>
                    </form>
                    <div v-else>
                        <h4>Message Sent!</h4>
                        <p>Hi {{ form.name }}, thanks for sending your message to us. We will get back to you at {{ form.email }} as soon as possible (normally within a working day).</p>
                        <p>Your message was:</p>
                        <p>{{ form.message }}</p>
                    </div>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->

<!--    <div class="">-->
<!--        <form v-if="showForm" id="contactForm" @submit.prevent="submitForm">-->
<!--            <div class="form-group">-->
<!--                <label for="name">Your name*</label>-->
<!--                <input id="name" type="text" name="name" v-model="form.name" @input="$v.form.name.$touch">-->
<!--                <p v-if="$v.form.name.$invalid && $v.form.name.$dirty" class="alert-warning">The name is required.</p>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--                <label for="email">Your email*</label>-->
<!--                <input id="email" type="email" name="email" v-model="form.email" @input="$v.form.email.$touch">-->
<!--                <p v-if="$v.form.email.$invalid && $v.form.email.$dirty" class="alert-warning">A valid email is required.</p>-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--                <label for="tel">Your telephone</label>-->
<!--                <input id="tel" type="text" name="tel" v-model="form.tel">-->
<!--            </div>-->
<!--            <div class="form-group">-->
<!--                <label for="message">Message*</label>-->
<!--                <textarea id="message" name="message" v-model="form.message" @input="$v.form.message.$touch"></textarea>-->
<!--                <p v-if="$v.form.message.$invalid && $v.form.message.$dirty" class="alert-warning">The message is required.</p>-->
<!--            </div>-->
<!--            <vue-recaptcha @verify="storeToken" @expired="token = null" sitekey="6LfF-aoZAAAAACFQzGjs1PR7jZC6KQFlESSFA00I" :loadRecaptchaScript="true">-->
<!--            </vue-recaptcha>-->
<!--            <div v-if="!submitActive" class="tooltip-wrapper" data-toggle="popover" data-trigger="hover" data-content="Please make sure the form is complete before you can submit the message.">-->
<!--                <button :disabled="!submitActive" class="btn btn-default contact-us" :class="{'disabled': !submitActive}">Submit</button>-->
<!--            </div>-->
<!--            <button v-else class="btn btn-default">Submit</button>-->
<!--            <p v-if="sendError" class="alert-danger">There was a problem sending your message: {{ sendError }}. <br> Your message could not be sent, please email or call us. Thanks</p>-->
<!--        </form>-->
<!--        <div v-else>-->
<!--            <h4>Message Sent!</h4>-->
<!--            <p>Hi {{ form.name }}, thanks for sending your message to us. We will get back to you at {{ form.email }} as soon as possible (normally within a working day).</p>-->
<!--            <p>Your message was:</p>-->
<!--            <p>{{ form.message }}</p>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        components: { VueRecaptcha },
        data: () => {
            return {
                showForm: true,
                sendError: '',
                token: '',
                form: {
                    name: '',
                    tel: '',
                    email: '',
                    message: ''
                },
            }
    },
        validations: {
            form:{
                name: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                message: {
                    required,
                }
            },
        },
        computed: {
            submitActive: function () {
                return !this.$v.form.$invalid && this.token;
            }
        },
        methods: {
            storeToken (response) {
                this.token = response;
                // console.log(this.token);
            },
            async submitForm() {
                axios.post('/messages', {
                    token: this.token,
                    name: this.form.name,
                    email: this.form.email,
                    tel: this.form.tel,
                    message: 'POWER-PIPE WEBINAR REQUEST: ' + this.form.message
                }).then(response => {
                    //clear form and show thanks message
                    this.showForm = false;
                }).catch(error => {
                    this.sendError = 'Sorry, there was a problem submitting the form. Please call or email us.';
                });
            },
        },
        mounted() {
            $(function () {
                $('[data-toggle="popover"]').popover();
            });
        }
    }
</script>
