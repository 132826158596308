/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
const WOW = require('wow.js/dist/wow');
window.wow = new WOW();


import Vue from 'vue/dist/vue'
window.Vue = Vue;
import Vuelidate from 'vuelidate';
window.Vue.use(Vuelidate);
import SmartTable from 'vuejs-smart-table';
window.Vue.use(SmartTable);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('contact-form', require('./components/ContactForm.vue').default);
Vue.component('testimonials', require('./components/Testimonials.vue').default);
Vue.component('product-selector', require('./components/ProductSelector.vue').default);
Vue.component('power-pipe-table', require('./components/PowerPipeTable').default);
Vue.component('savings-calculator', require('./components/SavingsCalculator').default);
Vue.component('webinar-form', require('./components/WebinarForm').default);

Vue.filter('money', function (value) {
    let val = (value/1).toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


jQuery(document).ready(function() {

    //Header transparency

    var frontHeader = jQuery('.pp-front-header');

    if (frontHeader) {
        jQuery(window).on('scroll', changeTransparency);
    }
    else {
        jQuery(window).off('scroll', changeTransparency);
    }

    function changeTransparency() {
        if (jQuery(window).scrollTop() > 50)
            frontHeader.addClass('pp-non-transparent');
        else
            frontHeader.removeClass('pp-non-transparent');
    }



    // Scroll to Step 1
    jQuery('.scroll-to-step-1').on("click", function() {
        jQuery('html, body').   animate({
            scrollTop: jQuery('h2:contains("How It Works")').offset().top - jQuery("#main-menu").outerHeight() - 10
        }, 1500);
    });

    //Dynamic menu
    jQuery('#menu-main-menu .menu-item-has-children').addClass('dropdown');
    jQuery('#menu-main-menu .menu-item-has-children > a').addClass('dropdown-toggle').attr('data-toggle', 'dropdown').append('<span class="caret"></span>');
    jQuery('#menu-main-menu .menu-item-has-children .sub-menu').addClass('dropdown-menu');

    // animate icon on hover Front-Page
    jQuery(".pp-feature").on({
        'mouseenter':function() {
            jQuery(this).find("img").animate({width:30+"%"}, 300);
        },'mouseleave':function() {
            jQuery(this).find("img").animate({width:22+"%"}, 100);
        }
    });

    jQuery(document).ready(function() {
        jQuery(document).on("click",'.toggle-item', function() {
            jQuery(this).find('.glyphicon').toggleClass("glyphicon-triangle-bottom").toggleClass("glyphicon-triangle-top");
        });

    });


    if (jQuery('.set_custom_images').length > 0) {
        if ( typeof wp !== 'undefined' && wp.media && wp.media.editor) {
            jQuery('.wrap').on('click', '.set_custom_images', function(e) {
                e.preventDefault();
                var button = jQuery(this);
                var id = button.prev();
                var img = button.prev().prev();
                wp.media.editor.send.attachment = function(props, attachment) {
                    id.val('');
                    img.attr("src", attachment.url);
                    id.val(attachment.url);
                };
                wp.media.editor.open(button);
                return false;
            });
        }
    };


});
