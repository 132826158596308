<template>
    <div class="row" id="savings-calculator">
        <div class="col-sm-12">
            <div class="panel panel-default">
                <div class="panel-body">
                    <h3>WWHR cost savings calculator</h3>
                    <p>Fill out the form below to find out how much you can save with our handy WWHR cost savings calculator!</p>
                    <form class="form-horizontal">
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Shower length</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="showerLength" aria-describedby="helpLength" />
                                    <div class="input-group-addon">minutes</div>
                                </div>
                                <span id="helpLength" class="help-block">Research by the <a href="https://www.energysavingtrust.org.uk/sites/default/files/reports/AtHomewithWater%287%29.pdf" target="_blank" title="Energy Saving Trust report on shower length">Energy Saving Trust</a> shows that the average shower length is 7.5 minutes.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">No. of showers</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="noOfShowers" aria-describedby="helpNo" />
                                    <div class="input-group-addon">per day</div>
                                </div>
                                <span id="helpNo" class="help-block">This is the average number of showers taken in the household per day, usually one per person(!).</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Power-Pipe Model</label>
                            <div class="col-sm-8">
                                <select class="form-control" v-model="selectedModel" aria-describedby="helpModel" >
                                    <option value="" disabled selected>Select an option</option>
                                    <option v-for="model in models" :value="model.sap2012">{{ model.model }}</option>
                                </select>
                                <span id="helpModel" class="help-block">If you are not sure which is the best Power-Pipe model for you, checkout our <a href="/price-and-size" title="Power-Pipe WWHR price and size guide" target="_blank">interactive Price & Sizing</a> guide. FYI our most efficient unit is the R4-84!</span>

                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Water Heater</label>
                            <div class="col-sm-8">
                                <select class="form-control" v-model="selectedHeater" @change="updateSelectedHeater">
                                    <option value="" style="color: red" disabled selected>Select an option</option>
                                    <option v-for="heater in heaters" :value="heater.name">{{ heater.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Heater efficiency</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <input class="form-control" type="text" :value="waterHeater * 100" @change="waterHeater = $event.target.value/100" aria-describedby="helpEfficiency" />
                                    <div class="input-group-addon">%</div>
                                </div>
                                <span id="helpEfficiency" class="help-block">NOTE: This is the overall water heating efficiency, so for systems incorporating a hot water cylinder, an adjustment has been made to account for standing losses. Heat Pumps have been adjusted to account for lower COP when heating water.</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Annual Saving</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <input class="form-control disabled" type="text" :value="energySavingKwh | money" disabled />
                                    <div class="input-group-addon">kWh</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Price of fuel</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <div class="input-group-addon">£/kWh</div>
                                    <input class="form-control" type="text" :value="energyCost | money" @change="energyCost = $event.target.value" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label">Cost saving</label>
                            <div class="col-sm-8">
                                <div class="input-group">
                                    <div class="input-group-addon">£</div>
                                    <input class="form-control disabled" type="text" v-model="moneySaving" disabled />
                                    <div class="input-group-addon">per year</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        name: 'SavingsCalculator',
        data: () => {
            return {
                models: [
                    {'model': 'R4-36', 'length': 910, 'drain': 110, 'series': 'R', 'maxShowers': 3, 'performance': 'low', 'price': 450, 'sap2009': 43.0, 'sap2012': 35.5, 'sapUplift': '10-12', 'img': '/images/models/r4-36.jpg'},
                    {'model': 'R4-60', 'length': 1520, 'drain': 110, 'series': 'R', 'maxShowers': 3, 'performance': 'med', 'price': 675, 'sap2009': 59.1, 'sap2012': 55.5, 'sapUplift': '15-17', 'img': '/images/models/r4-60.jpg'},
                    {'model': 'R4-84 (most efficient)', 'length': 2130, 'drain': 110, 'series': 'R', 'maxShowers': 3, 'performance': 'high', 'price': 850, 'sap2009': 67.0, 'sap2012': 63.7, 'sapUplift': '20-22', 'img': '/images/models/r4-84.jpg'},
                    {'model': 'X2-36', 'length': 910, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'performance': 'low', 'price': 375, 'sap2009': 35.4, 'sap2012': 32.2, 'sapUplift': '7-8', 'img': '/images/models/r2-36.jpg'},
                    {'model': 'X2-60', 'length': 1520, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'performance': 'med', 'price': 475, 'sap2009': 49.5, 'sap2012': 46.2, 'sapUplift': '12-13', 'img': '/images/models/r2-60.jpg'},
                    {'model': 'X2-84', 'length': 2130, 'drain': 50, 'series': 'X', 'maxShowers': 3, 'performance': 'high', 'price': 575, 'sap2009': 59.4, 'sap2012': 55.9, 'sapUplift': '15-16', 'img': '/images/models/r2-84.jpg'},
                    {'model': 'E2-36', 'length': 910, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'performance': 'low', 'price': 375, 'sap2009': 40.9, 'sap2012': 37.5, 'sapUplift': '9-10', 'img': '/images/models/r2-36.jpg'},
                    {'model': 'E2-60', 'length': 1520, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'performance': 'med', 'price': 475, 'sap2009': 54.3, 'sap2012': 50.7, 'sapUplift': '13-14', 'img': '/images/models/r2-60.jpg'},
                    {'model': 'E2-84', 'length': 2130, 'drain': 50, 'series': 'E', 'maxShowers': 1, 'performance': 'high', 'price': 575, 'sap2009': 63.7, 'sap2012': 60.3, 'sapUplift': '19-20', 'img': '/images/models/r2-84.jpg'},
                ],
                selectedModel: '',
                heaters: [
                    {name: 'Gas combi-boiler', efficiency: 0.92, energyCost: 0.06},
                    {name: 'Conventional gas boiler (with cylinder)', efficiency: 0.75, energyCost: 0.06},
                    {name: 'Electric immersion (with cylinder)', efficiency: 0.9, energyCost: 0.25},
                    {name: 'Air Source Heat Pump', efficiency: 2.5, energyCost: 0.25}
                ],
                selectedHeater: '',
                showerLength: 7.5,
                noOfShowers: 3,
                waterHeater: '',
                energyCost: '',
                flowRate: 11,
                waterHeat: 4.187,
                wasteTemp: 36,
                coldTemp: 10
            }
        },
        watch: {

        },
        computed: {
            showeredMinutes() {
                return this.flowRate * this.showerLength * this.noOfShowers * 365;
            },
            PPUplift() {
                return this.preHeatedWater - this.coldTemp;
            },
            recoveredEnergyGj() {
                return this.showeredMinutes * this.waterHeat * this.PPUplift / 1000000;
            },
            energySavingKwh() {
                // adjusted for heater efficiency and converted to kWh from GJ
                let value = (this.recoveredEnergyGj / this.waterHeater) * 278;
                if(value) {
                    return value;
                }
                return 0;
            },
            moneySaving() {
                return this.monetize(this.energySavingKwh * this.energyCost);
            },
            energyCostMoney() {
                return this.monetize(this.energyCost);
            },
            preHeatedWater() {
                return (26 * (this.selectedModel/100)) + 10;
            }
        },
        methods: {
            updateSelectedHeater() {
                let heater = this.heaters.filter(heater => heater.name === this.selectedHeater)[0];
                this.waterHeater = heater.efficiency;
                this.energyCost = heater.energyCost;
                // this.energyCost = this.heaters.name[this.selectedHeater].energyCost;
            },
            monetize(value) {
                let val = (value/1).toFixed(2)
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        },
        mounted() {
            $(function () {
                $('[data-toggle="popover"]').popover();
            });
        }
    }
</script>

