<template>
    <div class="">
        <form v-if="showForm" id="contactForm" @submit.prevent="submitForm">
            <div class="form-group">
                <label for="name">Your name*</label>
                <input id="name" type="text" name="name" v-model="form.name" @input="$v.form.name.$touch">
                <p v-if="$v.form.name.$invalid && $v.form.name.$dirty" class="alert-warning">The name is required.</p>
            </div>
            <div class="form-group">
                <label for="email">Your email*</label>
                <input id="email" type="email" name="email" v-model="form.email" @input="$v.form.email.$touch">
                <p v-if="$v.form.email.$invalid && $v.form.email.$dirty" class="alert-warning">A valid email is required.</p>
            </div>
            <div class="form-group">
                <label for="tel">Your telephone</label>
                <input id="tel" type="text" name="tel" v-model="form.tel">
            </div>
            <div class="form-group">
                <label for="message">Message*</label>
                <textarea id="message" name="message" v-model="form.message" @input="$v.form.message.$touch"></textarea>
                <p v-if="$v.form.message.$invalid && $v.form.message.$dirty" class="alert-warning">The message is required.</p>
            </div>
            <vue-recaptcha @verify="storeToken" @expired="token = null" sitekey="6LfF-aoZAAAAACFQzGjs1PR7jZC6KQFlESSFA00I" :loadRecaptchaScript="true">
            </vue-recaptcha>
            <div v-if="!submitActive" class="tooltip-wrapper" data-toggle="popover" data-trigger="hover" data-content="Please make sure the form is complete before you can submit the message.">
                <button :disabled="!submitActive" class="btn btn-default contact-us" :class="{'disabled': !submitActive}">Submit</button>
            </div>
            <button v-else class="btn btn-default">Submit</button>
            <p v-if="sendError" class="alert-danger">There was a problem sending your message: {{ sendError }}. <br> Your message could not be sent, please email or call us. Thanks</p>
        </form>
        <div v-else>
            <h4>Message Sent!</h4>
            <p>Hi {{ form.name }}, thanks for sending your message to us. We will get back to you at {{ form.email }} as soon as possible (normally within a working day).</p>
            <p>Your message was:</p>
            <p>{{ form.message }}</p>
        </div>
    </div>
</template>

<script>
    import { required, email } from 'vuelidate/lib/validators';
    import VueRecaptcha from 'vue-recaptcha';

    export default {
        components: { VueRecaptcha },
        data: () => {
            return {
                showForm: true,
                sendError: '',
                token: '',
                form: {
                    name: '',
                    tel: '',
                    email: '',
                    message: ''
                },
            }
    },
        validations: {
            form:{
                name: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                message: {
                    required,
                }
            },
        },
        computed: {
            submitActive: function () {
                return !this.$v.form.$invalid && this.token;
            }
        },
        methods: {
            storeToken (response) {
                this.token = response;
                // console.log(this.token);
            },
            async submitForm() {
                axios.post('/messages', {
                    token: this.token,
                    name: this.form.name,
                    email: this.form.email,
                    tel: this.form.tel,
                    message: this.form.message
                }).then(response => {
                    //clear form and show thanks message
                    this.showForm = false;
                }).catch(error => {
                    this.sendError = 'Sorry, there was a problem submitting the form. Please call or email us.';
                });
            },
        },
        mounted() {
            $(function () {
                $('[data-toggle="popover"]').popover();
            });
        }
    }
</script>
